import styles from "./Utility.module.scss";
import BOX2 from "assets/mobile-box2.png";
import BOX1 from "assets/mobile-box1.png";
import BOX3 from "assets/mobile-box3.png";
import UTIL from "assets/utilitybox.png";
import { useMediaQuery } from "react-responsive";
import { ADDRESS, ETH_ADDRESS } from "constant/Address";
import { bnToUint256 } from "starknet/dist/utils/uint256";
import { Provider } from "starknet";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { validateAndParseAddress } from "starknet";

const Utility = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });
  const [mintCount, setMintCount] = useState<number>(444);

  const account = useSelector((state: RootState) => state.account.account);
  const test = async () => {
    const mintCountTx = {
      contractAddress: ADDRESS,
      entrypoint: "totalSupply",
    };
    try {
      if (account) {
        const res: any = await account.account.callContract(mintCountTx);
        setMintCount(parseInt(res.result[0], 16));
        console.log(res.result[0], 16);
      } else {
        const provider: any = new Provider();
        const res: any = await provider.callContract(mintCountTx);
        setMintCount(parseInt(res.result[0], 16));
        console.log(res.result[0], 16);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    test();
  }, [account]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>UTILITY</div>
      {isMobile ? (
        <div className={styles.mobile}>
          <img src={BOX1}></img>
          <img src={BOX2}></img>
          <img src={BOX3}></img>
        </div>
      ) : (
        <div className={styles.utilities}>
          <img src={UTIL}></img>
        </div>
      )}
      <div className={styles.buttons}>
        <div className={styles.border}>
          <div
            className={styles.mint}
            style={
              isMobile
                ? { width: `${50 + (mintCount / 444) * 226}px` }
                : { width: `${50 + (mintCount / 444) * 306}px` }
            }
          ></div>
          <div className={styles.count}>{mintCount}/444</div>
        </div>
      </div>
    </div>
  );
};
export { Utility };
