import { useState } from "react";
import styles from "./Collection.module.scss";
import TITLE from "assets/nftCollection.svg";
import FORLOCATION from "assets/forLocation.png";
import { useMediaQuery } from "react-responsive";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import {
  NFTS,
  Locations,
  LocationsForMobile,
} from "components/Collection/infos";

import COIN from "assets/coin.mp3";

const Collection = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });
  const [part, setPart] = useState<number>(0);


  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <img src={TITLE}></img>
      </div>
      <div className={styles.collection}>
        {isMobile ? (
          <div className={styles.row2}>
            <img src={NFTS[0]} style={LocationsForMobile[part % 6]}></img>
            <img src={NFTS[1]} style={LocationsForMobile[(part + 1) % 6]}></img>
            <img src={NFTS[2]} style={LocationsForMobile[(part + 2) % 6]}></img>
            <img src={NFTS[3]} style={LocationsForMobile[(part + 3) % 6]}></img>
            <img src={NFTS[7]} style={LocationsForMobile[(part + 4) % 6]}></img>
            <img
              src={NFTS[10]}
              style={LocationsForMobile[(part + 5) % 6]}
            ></img>
            <img src={FORLOCATION} className={styles.forLocation}></img>
          </div>
        ) : (
          <div className={styles.row1}>
            <img src={NFTS[0]} style={Locations[part % 12]}></img>
            <img src={NFTS[1]} style={Locations[(part + 1) % 12]}></img>
            <img src={NFTS[2]} style={Locations[(part + 2) % 12]}></img>
            <img src={NFTS[3]} style={Locations[(part + 3) % 12]}></img>
            <img src={NFTS[4]} style={Locations[(part + 4) % 12]}></img>
            <img src={NFTS[5]} style={Locations[(part + 5) % 12]}></img>
            <img src={NFTS[6]} style={Locations[(part + 6) % 12]}></img>
            <img src={NFTS[7]} style={Locations[(part + 7) % 12]}></img>
            <img src={NFTS[8]} style={Locations[(part + 8) % 12]}></img>
            <img src={NFTS[9]} style={Locations[(part + 9) % 12]}></img>
            <img src={NFTS[10]} style={Locations[(part + 10) % 12]}></img>
            <img src={NFTS[11]} style={Locations[(part + 11) % 12]}></img>
            <img src={FORLOCATION} className={styles.forLocation}></img>
          </div>
        )}
      </div>
      <div className={styles.parts}>
        <div
          className={styles.back}
          onClick={() => {
            if (part === 0) {
              setPart(11);
            } else {
              setPart(part - 1);
            }
          }}
        >
          <MdOutlineArrowBackIos size={isMobile ? 20 : 20} />
        </div>
        {NFTS.map((data: string, i: number) => {
          return (
            <div key={i} className={part === i ? styles.on : styles.off}>
              &nbsp;
            </div>
          );
        })}
        <div
          className={styles.next}
          onClick={() => {
            if (part === 11) {
              setPart(0);
            } else {
              setPart(part + 1);
            }
          }}
        >
          <MdOutlineArrowForwardIos size={isMobile ? 20 : 20} />
        </div>
      </div>
    </div>
  );
};
export { Collection };
