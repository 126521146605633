import ZERO from "assets/NFTs/0.png";
import ONE from "assets/NFTs/1.png";
import TWO from "assets/NFTs/2.png";
import THREE from "assets/NFTs/3.png";
import FOUR from "assets/NFTs/4.png";
import FIVE from "assets/NFTs/5.png";
import SIX from "assets/NFTs/6.png";
import SEVEN from "assets/NFTs/7.png";
import EIGHT from "assets/NFTs/8.png";
import NINE from "assets/NFTs/9.png";
import TEN from "assets/NFTs/10.png";
import ELEVEN from "assets/NFTs/11.png";
import { CSSProperties } from "react";
export const Locations: Array<CSSProperties> = [
  {
    left: "0%",
    top: "0%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "25%",
    top: "0%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "50%",
    top: "0%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "75%",
    top: "0%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "87.5%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "75%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "62.5%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "50%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "37.5%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "25%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "12.5%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "0%",
    top: "66.8%",
    width: "12.5%",
    transition: "all 1s",
    position: "absolute",
  },
];

export const LocationsForMobile: Array<CSSProperties> = [
  {
    left: "0%",
    top: "0%",
    width: "50%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "50%",
    top: "0%",
    width: "50%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "75%",
    top: "66.8%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "50%",
    top: "66.8%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "25%",
    top: "66.8%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
  {
    left: "0%",
    top: "66.8%",
    width: "25%",
    transition: "all 1s",
    position: "absolute",
  },
];

export const NFTS = [
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
  ELEVEN,
];
