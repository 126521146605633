import styles from "./Intro.module.scss";
import INTRO from "assets/intro.png";
import ROCK from "assets/rock.png";
import LOGO from "assets/nftCollection.svg";
import { useConnectStarknetAccount } from "hooks/useConnectStarknetAccount";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ADDRESS, ETH_ADDRESS } from "constant/Address";
import { bnToUint256 } from "starknet/dist/utils/uint256";
import { Toaster, toast } from "react-hot-toast";
import { PROOFS } from "constant/Proofs";
import { ethers, BigNumber } from "ethers";
import { validateAndParseAddress } from "starknet";

enum MINT {
  "WLMINT",
  "PUBLICMINT",
}
const Intro = () => {
  const whichMint: MINT = MINT.WLMINT;

  const { silentConnectWallet } = useConnectStarknetAccount();
  const account = useSelector((state: RootState) => state.account.account);
  useEffect(() => {
    silentConnectWallet();
  }, []);

  const test = async () => {
    // const addresses = testWlAddress.map((v) => {
    //   return BigNumber.from(v)._hex.toLowerCase()
    // })
    try {
      // const proof = merkleProof(BigNumber.from(account.selectedAddress)._hex, addresses)
      // const mintPrice = 0.05 * 10 ** 18;
      const mintPriceBN: BigNumber = ethers.utils.parseUnits("0.05", "18");
      // console.log(mintPriceBN)
      const allowance = bnToUint256(mintPriceBN.toString());

      console.log(allowance, validateAndParseAddress(account.selectedAddress));

      if (whichMint === MINT.WLMINT) {
        if (
          PROOFS[0][validateAndParseAddress(account.selectedAddress)] ===
          undefined
        ) {
          toast.error("This address is not on the whitelist!");
        } else {
          const wlMintTX = [
            {
              contractAddress: ETH_ADDRESS,
              entrypoint: "approve",
              calldata: [ADDRESS, allowance.low, allowance.high],
            },
            {
              contractAddress: ADDRESS,
              entrypoint: "wlMint",
              calldata: [
                PROOFS[0][validateAndParseAddress(account.selectedAddress)]
                  .proof.length,
                ...PROOFS[0][validateAndParseAddress(account.selectedAddress)]
                  .proof,
              ],
            },
          ];
          const res: any = await account.account.execute(wlMintTX);
          console.log(res);
        }
      } else if (whichMint === MINT.PUBLICMINT) {
        const publicMintTX = [
          {
            contractAddress: ETH_ADDRESS,
            entrypoint: "approve",
            calldata: [ADDRESS, allowance.low, allowance.high],
          },
          {
            contractAddress: ADDRESS,
            entrypoint: "publicMint",
            calldata: [],
          },
        ];
        const res: any = await account.account.execute(publicMintTX);
        console.log(res);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const publicMint = async () => {
    try {
      const mintPriceBN: BigNumber = ethers.utils.parseUnits("0.05", "18");
      const allowance = bnToUint256(mintPriceBN.toString());

      const publicMintTX = [
        {
          contractAddress: ETH_ADDRESS,
          entrypoint: "approve",
          calldata: [ADDRESS, allowance.low, allowance.high],
        },
        {
          contractAddress: ADDRESS,
          entrypoint: "publicMint",
          calldata: [],
        },
      ];
      const res: any = await account.account.execute(publicMintTX);
      console.log(res);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.navbar}>
        <img src={LOGO}></img>
      </div>
      <a
        className={styles.connectButton}
        target="_blank"
        href={
          "https://pyramid.market/collection/0x12f8e318fe04a1fe8bffe005ea4bbd19cb77a656b4f42682aab8a0ed20702f0"
        }
        onClick={() => {
          // connectAccount();
          // window.location = "http://www.google.com/";
          // window?.location.href =""
        }}
      >
        {/* {account ? parseAddress(account.account.address) : "Connect Wallet"} */}
        Buy / Sell StarkRocks
      </a>
      <img className={styles.rock} src={ROCK}></img>
      <div className={styles.intro}>
        <img src={INTRO}></img>
        <div className={styles.header}>
          <div>
            LET’S<span>ROCK</span>
            {/* <br /> */}
          </div>
          <div>
            <span>STARKNET</span>
          </div>
          {/* <div
            className={styles.button}
            onClick={() => {
              toast.error("Mint is over!");
            }}
          >
            Mint
          </div> */}
        </div>
      </div>
    </div>
  );
};
export { Intro };
