import styles from "./Contact.module.scss";
import { BsTwitter } from "react-icons/bs";

const Contact = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.twitter}>
        <a href="https://twitter.com/StarkNetRocks" target="_blank">
          <div className={styles.logo}>
            <BsTwitter size={44} />
          </div>
        </a>
        <a href="https://twitter.com/StarkNetRocks" target="_blank">
          <div className={styles.text}>@StarkNetRocks</div>
        </a>
      </div>
    </div>
  );
};
export { Contact };
