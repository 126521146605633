import { connect, disconnect } from "get-starknet"
import { useDispatch } from "react-redux";
import { setStarknetAccount } from "store/slicers/account";
import { useSelector } from "react-redux";
export const useConnectStarknetAccount = () => {
  const dispatch = useDispatch();

  const Values = useSelector((state: any) => state.starknet);

  const connectAccount = async () => {
      const starknet: any = await connect({ showList: true });
      await starknet?.enable();
      if (!starknet) {
        throw Error(
          "User rejected wallet selection or silent connect found nothing"
        );
      }
      if (starknet.isConnected) {
        dispatch(setStarknetAccount(starknet));
      } else {
        console.log("Problem");
      }

  };

  const silentConnectWallet = async () => {
    const starknet = await connect({ showList: false });
    if (!starknet?.isConnected) {
      await starknet?.enable({ showModal: false });
      dispatch(setStarknetAccount(starknet));
    }
  };



  return {
    connectAccount,
    silentConnectWallet,
  };
};
