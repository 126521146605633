import { useInitialTheme } from "hooks/useInitialTheme";
import { Landing } from "pages";

function App() {
  useInitialTheme();
  return (
    <div>
      <Landing />
    </div>
  );
}

export default App;
