import styles from "./Landing.module.scss";
import { Example, Intro, Utility, Contact, Collection } from "components";

const Landing = () => {
  return (
    <div className={styles.wrapper}>
      {/* <Example /> */}
      <Intro />
      <Utility />
      <Collection />
      <Contact />
    </div>
  );
};
export { Landing };
